import { useState } from 'react';
import {Button, Modal, Form} from 'react-bootstrap';

function ReviewPopup(props){
    const [textV, setTextV] = useState("");
    const [textTitle, setTextTitle] = useState("");

    const [showAlretMsg, setShowAlretMsg] = useState(null);

    const handleClose = () => {
        props.starSet(null)
        props.showStC(false)
    }

    async function postReviewsData(){
        // CmpId, UsrId, UsrName, RVRate, RTitle, TBody
        var signupDict = {
            "CmpId" : props.cmpId,
            "UsrId" : props.userId,
            "UserName" : props.userName,
            "RVRate" : props.starN,
            "RTitle" : textTitle,
            "TBody" : textV,
            "CmpName": props.cmpName,
            "CmpLnk": props.cmpLnk
        }

        const resp = await fetch("/api/v1/post_review",{
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(signupDict)
        })
        if (await resp.status === 200){
            const getReviewRep = await fetch(`/api/v1/get_review?code=CmpId&value=${props.cmpId}`, {method:"GET" })
            const reviewJson = await getReviewRep.json()
            if (await getReviewRep.status === 200){
                props.setListOfReviews(reviewJson)
                props.showStC(false)
            } else {
                setShowAlretMsg("timeout please try agine. after few secounds ago.")
            }
        } else {
            setShowAlretMsg("timeout please try agine. after few secounds ago.")
        }
        
    }

    const handlePublish = () =>{
        if (props.starN === null){
            setShowAlretMsg("Please Select Rateing Star.")
        } else if (textTitle.length <= 10){
            setShowAlretMsg("Please Write Review Title Minimum '10' Chareters")
        } else if (textV.length <= 60){
            setShowAlretMsg("Please Write Review Message Minimum '60' Chareters")
        } else {
            setShowAlretMsg(null)
            postReviewsData()
        }
    }

    return(<>
            <Modal
                size='lg'
                show={props.showSt}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.cmpName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=' mb-4 mt-3'>
                        <div className='d-flex justify-content-center'>
                            {props.starC(true)}
                        </div>
                        {props.starN && <div className='d-flex justify-content-center text-success'>
                            Selected For " {props.starN} " Rete.
                        </div> }
                    </div>
                    
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        className='mb-3'
                        as="textarea"
                        rows={2}
                        value={textTitle}
                        pattern='.{20}'
                        required
                        title='20 characters minimum'
                        placeholder='Write Title "10" characters minimum'
                        onChange={e => setTextTitle(e.target.value)}
                    />
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        value={textV}
                        pattern='.{20}'
                        required
                        title='60 characters minimum'
                        placeholder='Write Reviews "60" characters minimum'
                        onChange={e => setTextV(e.target.value)}
                    />
                    {showAlretMsg && <div className='text-danger'>{showAlretMsg}</div>}
                </Modal.Body>
                
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handlePublish}>Publish</Button>
                </Modal.Footer>
            </Modal>
    </>)
}

export default ReviewPopup;