
import {Container, Row, Card,Col, Navbar,Toast, Image, Stack, Button} from "react-bootstrap"
import {useEffect, useState} from "react"
import { useParams, Link } from "react-router-dom";

import ReviewPopup from "./ReviewPopup"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"


function ReviewPage(props){
    const { cateCode, cmpName } = useParams();
    const [strating, setStrating] = useState(null);
    const [sthover, setHover] = useState(null);
    const [repopup, setRepopup] = useState(false);
    const [compayInfo, setCompanyInfo] = useState({})
    const [listOfReviews, setListOfReviews] = useState([]);
    const [existRate, setExistRate] = useState(null);

    useEffect(()=>{
        getCmpDetails()
    },[])

    async function getCmpDetails(){


        let responce = await fetch(`/api/v1/company_info?type=${cateCode}&cmp=${cmpName}`)
        let jsonDate = await responce.json()
        if (responce.status === 200) {
            setCompanyInfo(jsonDate);
            
            const UserInfoCheck = await fetch("/api/v1/health_check")
            const UserInfoCheckJ = await UserInfoCheck.json();
            if (await UserInfoCheck.status === 200){
    
                const getReviewCheckRep = await fetch(`/api/v1/check_review?CmpId=${jsonDate.cmpid}&UsrId=${UserInfoCheckJ["msg"][1]}`, {method:"GET" })
                const reviewCheckJson = await getReviewCheckRep.json()
                if (await getReviewCheckRep.status === 200){
                    setExistRate(reviewCheckJson)
                    
                    if (reviewCheckJson !== null){
                        setStrating(reviewCheckJson.RVRate)
                    }
                    
                }
    
            }
            
        }
        // console.log(jsonDate)
        const getReviewRep = await fetch(`/api/v1/get_review?code=CmpId&value=${jsonDate.cmpid}`, {method:"GET" })
        const reviewJson = await getReviewRep.json()
        if (await getReviewRep.status === 200){
            setListOfReviews(reviewJson)
        }
    }

    function OverViewRate(inActive, sizess){
        return([...Array(5)].map((st, index) => {
            return(
                <div role="button" className="shadow bi bi-star-fill me-2 display-6"
                    style={index <= inActive ?{color: "#ffc107" }: {color:"#e4e5e9"}}
                />
            )
        }))
    }

    function OverViewRate1(inActive, sizess){
        return([...Array(5)].map((st, index) => {
            return(
                <div role="button" className=" bi bi-star-fill me-2 display-7"
                    style={index <= inActive ?{color: "#ffc107" }: {color:"#e4e5e9"}}
                />
            )
        }))
    }

    function handleStartRate(realAction) {
        return([...Array(5)].map((star, index) => {
            const currentRating = index + 1;
            return(
                <label>
                    <input
                        type="radio"
                        name="rating"
                        className="d-none"
                        value={currentRating}
                        onClick={() => {
                            if (realAction === true){
                                if (strating === null){
                                    setStrating(currentRating)
                                }else{
                                    setHover(null)
                                }
                            } else if (strating === null) {
                                if (props.aliveSt){
                                    setRepopup(true)
                                } else {
                                    props.onSingup(true)
                                }
                                
                            } 
                        }}
                    />
                    <div role="button" className="bi bi-star-fill me-2 display-5"
                        style={currentRating <= (sthover || strating) ?{color: "#ffc107" }: {color:"#e4e5e9"}}
                        
                        onMouseEnter={() => {
                            strating === null ?
                            setHover(currentRating) : setHover(null)
                        }}
                        onMouseLeave={() => setHover(null)}
                    />
                </label>
            )
    }))}

    const ProfileLetter = ({name}) => {
        return(<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:50, height:50, borderRadius:"50%",backgroundColor:"rgb(70, 200, 36)"}}>
                <span style={{color:"white", fontSize:"20px", fontWeight:"bold"}}>{name.charAt(0).toUpperCase()}</span>
            </div>
        </div>)
    }

    return(<div className="" style={{"marginTop":"5rem"}}>
        {
            repopup === true && <ReviewPopup 
                showSt={repopup} showStC={setRepopup}
                starC={handleStartRate} starN={strating} cmpName={compayInfo.name}
                starSet={setStrating} userId={props.userId} cmpId={compayInfo.cmpid}
                userName={props.userName} setListOfReviews={setListOfReviews} cmpLnk={`/review/${cateCode}/${cmpName}`}
            />
        }

        <Container fluid className="p-4">
            <Card  className="p-3" style={{margin:"0 auto", float:"none"}}>
                <Row className="">
                    <Col md={2} />
                    <Col md={3} className="">
                        <Image fluid src={compayInfo.img} rounded width={220} height={120} />
                    </Col>
                    <Col md={4} className="">
                        <Card.Body>
                            <Card.Title style={{fontWeight:"bolder", fontFamily:"serif"}} className="">
                                {compayInfo.name}
                            </Card.Title>
                            <Card.Text className="d-flex">
                                {OverViewRate(compayInfo.rvrate-1)}
                            </Card.Text>
                            <h3 className="mt">{compayInfo.rvrate}</h3>
                            <Button disabled variant="outline-success" className="">
                                <i className="bi bi-shield-shaded mb-1 me-2"/>
                                Verify this business
                            </Button>
                        </Card.Body>
                    </Col>
                    <Col md={2} className="mt-3">
                        <Button variant="outline-primary" href={compayInfo.web} target="_black" className="mb-2">
                            <div className="d-flex">
                                <i class="bi bi-globe me-2"/>{compayInfo.web}
                            </div>
                        </Button>
                    </Col>
                    <Col md={2} />
                </Row>
            </Card>
        </Container>
        
        <Container >
            <Row  className="p-3 mb-5 shadow d-flex justify-content-center">
                <Col md={2} />
                <Col xs={12} md={4} className="mb-3 mt-2 d-flex justify-content-center">
                    <Button disabled={existRate === null ? false : true} onClick={()=>{ strating === null &&  props.aliveSt ? setRepopup(true) :  strating === null && props.onSingup(true) }} variant="success p-3">
                        Write Reviews
                    </Button>
                </Col>
                <Col md={4} className="mb-3 d-flex d-none d-sm-block justify-content-center">
                    {handleStartRate(false)}
                </Col>
                <Col md={2} />
            </Row>
        </Container>

        <Container className="">
            <Row className="mb-3">
                <Col xs={12} md={4} className="me-1 mb-4">
                    <Card>
                        <Card.Body>
                            <Card.Title className="">
                                About {compayInfo.name}
                            </Card.Title>
                            <hr/>
                                <div className="p-3">
                                    <div className="mb-2 font-blod">
                                        <i class="bi bi-telephone me-2 mb-3"/> {compayInfo.phone}
                                    </div>
                                    <div className="mb-2 font-blod">
                                        <i class="bi bi-envelope-fill me-2 mb-3"/> {compayInfo.mail}
                                    </div>
                                    <div className="mb-2 font-blod">
                                        <i class="bi bi-pin-map me-2 mb-3"/> {compayInfo.location}
                                    </div>
                                </div>
                                <small>
                                    {compayInfo.note}
                                </small>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={7}>
                    {
                        listOfReviews && listOfReviews.map((rvitem)=>(
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title className="d-flex">
                                        {<ProfileLetter name={rvitem.Name} />}
                                        <p className="mt-3 ms-3">{rvitem.Name}</p>
                                    </Card.Title>
                                    <div className="d-flex">
                                        {OverViewRate1(rvitem.RVRate-1)}
                                        <small className="ms-3">{Date(rvitem.PostDate).toString()}</small>
                                    </div>
                                    <hr/>
                                    <Card.Subtitle className="mb-2">{rvitem.Title}</Card.Subtitle>
                                    <small>{rvitem.Body}</small>
                                </Card.Body>
                                <Card.Footer>
                                    <i class="bi bi-share me-3 "/>
                                    <i class="bi bi-chat-left-text"/>
                                </Card.Footer>
                            </Card>
                        ))
                    }
                </Col>
            </Row>
        </Container>
    </div>)
}

export default ReviewPage;