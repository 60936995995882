import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

function SignupPage(props) {
    const [signupDict, setSignDict] = useState({"fname":"", "mail":"", "password":"", "conpassword":"", "type":100});
    const [errWarSt, setErrWarSt] = useState(false);
    const [errWarTxt, setErrWarTxt] = useState('');
    const [loginORsingup, setLoginORsignup] = useState(true);

    async function handlerSignUp () {

        if (signupDict.password === signupDict.conpassword || loginORsingup === true){
        
          if (loginORsingup === true){
            signupDict.type = 100;
            var urlFetch = `/api/v1/login`
          } else {
            signupDict.type = 101;
            var urlFetch = `/api/v1/signup`
          }
          
          console.log(signupDict);

          const resp = await fetch(urlFetch,{
              method: "POST",
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(signupDict)
          })

          const respJson = await resp.json()
          
          if (resp.status === 200 ){
            console.log(respJson)
            setErrWarSt(false)
            setErrWarTxt(``)
            props.aliveSt(true)
            props.onHideInner(false)
            props.usernamepic(respJson.fname)
            props.setUserIdC(respJson.usrId)
          } else {
            setErrWarSt(true)
            if (signupDict.type === 101){
              setErrWarTxt(`This "User Name" or "Mail Id" already teken. please use anther one.`)
            } else {
              setErrWarTxt(`This "Mail Id" or "Passwd" Worng Try agin`)
            }
            
          }
        } else {
          setErrWarTxt(`Password and Comfrom Password. Can't Mach !`)
          setErrWarSt(true)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target;
        setSignDict((prevData) => ({
          ...prevData,
          [name]: value
        }));
      };

  return (
    <Modal
      {...props}
      // size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ display: 'block'}}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {loginORsingup === true ? <>Login In</>:<>Sing Up</>}
        </Modal.Title>
      </Modal.Header>
      
        <Modal.Body className='align-items-center'>
        <Form>  
            <Form.Group>
              {loginORsingup === false && <>
                <Form.Label style={{fontWeight:"bold"}}>Name</Form.Label>
                <Form.Control required value={signupDict.fname} name='fname' onChange={e=>handleChange(e)} className='p-2 mb-2' type='text' placeholder='Enter Name' />
              </>}
                
                <Form.Label style={{fontWeight:"bold"}}>Mail Address</Form.Label>
                <Form.Control required value={signupDict.mail} onChange={e=>handleChange(e)} className='p-2 mb-2' name='mail' type='email' placeholder='Enter Email Address' />
                
                <Form.Label style={{fontWeight:"bold"}}>Password</Form.Label>
                <Form.Control required value={signupDict.password} onChange={e=>handleChange(e)} className='p-2 mb-2' name='password' type='password' placeholder='Enter Password' />
                
                {loginORsingup === false && <>
                  <Form.Label style={{fontWeight:"bold"}}>Confrom Password</Form.Label>
                  <Form.Control required value={signupDict.conpassword} onChange={e=>handleChange(e)} className='p-2 mb-2' name='conpassword' type='password' placeholder='Enter Confrom Password' />
                </>}
            
                {
                    errWarSt && <Form.Text style={{color:"red"}} >{errWarTxt}</Form.Text>
                }

                {
                  loginORsingup === true ? <div>
                    <a href='#' onClick={()=>{
                      setLoginORsignup(false)
                      setErrWarSt(false)
                    }}>Create new account ?</a>
                  </div> : <div>
                    <a href='#' onClick={()=>{
                      setLoginORsignup(true)
                      setErrWarSt(false)
                    }}>Login already has account</a>
                  </div>
                }
                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer>
            <Button variant='success' onClick={()=>handlerSignUp()} type='submit'>Submit</Button>
            <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      
    </Modal>
  );
}

export default SignupPage;