
import {useEffect, useState} from "react"
import Typewriter from "typewriter-effect"
import {useNavigate} from "react-router-dom"
import { Card, Container,Image, Col, Row, Button } from "react-bootstrap";
import AOS from "aos";
import Marquee from "react-fast-marquee";

import userAvater from "../img/useravater.jpg"
import rvstart from "../img/reviewstart.jpg"
import shield1 from "../img/shield.png"
import achivement from "../img/archivement.png"
import mainImg2 from "../img/main1.jpg"
import jobWork1 from "../img/jobwork.jpg"
import experiance from "../img/expriance.jpg"

import "./HomePage.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "aos/dist/aos.css"

const randomColor = () =>{
    return("#" + ((1<<24)*Math.random() | 0).toString(16))
}

const ProfileLetter = ({name}) => {
    return(<div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:50, height:50, borderRadius:"50%",backgroundColor:randomColor()}}>
            <span style={{color:"white", fontSize:"20px", fontWeight:"bold"}}>{name.charAt(0).toUpperCase()}</span>
        </div>
    </div>)
}

const OverViewRate1 = ({inActive, sizess}) => {
    return([...Array(5)].map((st, index) => {
        return(
            <div role="button" className=" bi bi-star-fill me-2"
                style={index <= inActive ? {color: "#ffc107" }: {color:"#e4e5e9"}}
            />
        )
    }))
}

function HomePage(){
    const [prePostReviews, setPrePostReviews] = useState([])
    const [categoriesList, setCategoriesList] = useState([])
    
    const historyObj = useNavigate();

    useEffect(()=> {
        AOS.init({duration: 3000});
        getPrePostReviews();
        getCategoriesList();
    }, [])

    async function getCategoriesList(){
        const responce = await fetch("/api/v1/category")
        const jsonData = await responce.json();
        if (responce.status === 200){
            setCategoriesList(jsonData);
        }
    }

    async function getPrePostReviews(){
        const respc = await fetch("/api/v1/prepostviews");
        if (respc.status === 200){
            const respcJson = await respc.json()
            // console.log(respcJson)
            setPrePostReviews(respcJson)
        }
    }

    const MarqueeHandler = ({stDirection}) =>{
        return(
            <Marquee className="mb-3" direction={stDirection} pauseOnHover={true}>
                {prePostReviews.map((item)=>(
                    <Card className="me-4" style={{width:"25rem", height:"17rem"}}>
                        <Card.Body>
                            <Card.Header>
                                <div className="d-flex">
                                    <ProfileLetter name={item.name}/>
                                    <h4 className="mt-3 ms-3">{item.name}</h4>
                                </div>
                                <div className="d-flex"><OverViewRate1 inActive={item.rate}/></div>
                                <Card.Subtitle  className="text-muted mt-1">
                                    reviewd <a style={{color:"#000000"}} href="/review/Royal%20Immigration%20Service">{item.cmpName}</a>
                                </Card.Subtitle>
                            </Card.Header>

                            <div className="mt-3" style={{overflow:"hidden", textOverflow:"ellipsis",display:"-webkit-box", "webkitBoxOrient":"vertical", WebkitLineClamp:4}}>
                                <h6>{item.title}</h6>
                                {item.msg}
                            </div>
                        </Card.Body>
                    </Card>
                ))}
            </Marquee>
        )
    }

    const cateTableHandler = (value, cateCode) => {
        console.log(value)
        historyObj(`/categories/${cateCode}/${value}`);
    };

    return(<>
        <div className="">
        <Container className="">
            <Row>
                <Col md={2}></Col>
                <Col md={12} style={{marginTop:290, marginBottom: 220}} className="text-center justify-content-center" >
                    <h3  style={{fontWeight:"bold"}} className="mt-sm-4 display-4">
                        Share your experience for <span style={{fontFamily:"fantasy", color:"#f25124"}}><Typewriter options={{autoStart: true, loop: true}}
                        onInit={(twObj)=>{
                            twObj.typeString("Restaurants").pauseFor(3000).deleteAll()
                            .typeString("Tour and Travel").pauseFor(3000).deleteAll()
                            .typeString("Real Estate").pauseFor(3000).deleteAll()
                            .typeString("Medical").pauseFor(3000).deleteAll()
                            .typeString("Hotels").pauseFor(3000).deleteAll()
                            .typeString("Banking").pauseFor(3000).deleteAll()
                            .typeString("Shoping Mall").pauseFor(3000).deleteAll()
                            .typeString("Immigration").pauseFor(3000).deleteAll()
                            .typeString("Interiors Design").pauseFor(3000).deleteAll()
                            .start();
                        }}
                    /></span>
                    </h3>
                </Col>
                <Col md={2}></Col>
            </Row>
        </Container>

        <Container className="text-center pt-4" fluid data-aos="fade-up">
            <Row>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={6} md={3}>
                    <Image src={userAvater} width={87} height={87}/>
                    <div className="fs-2 ">
                        10 Lakh+
                        <div className="fs-6">Users Base</div>
                    </div>
                </Col>

                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={6} md={3}>
                    <Image src={rvstart} width={76} height={76}/>
                    <div className="fs-2 ms-1">
                        80 Lakh+
                        <div className="fs-6">Company Reviews</div>
                    </div>
                </Col>

                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={6} md={3}>
                    <Image src={shield1} width={69} height={77}/>
                    <div className="fs-2 ms-1">
                        1 Crore+
                        <div className="fs-6">Verified Reviews</div>
                    </div>
                </Col>

                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={6} md={3}>
                    <Image src={achivement} width={67} height={78}/>
                    <div className="fs-2 ms-1">
                        2 Lakh+
                        <div className="fs-6">Achievement</div>
                    </div>
                </Col>
            </Row>
        </Container>

        <Container className="" style={{marginTop:"8vw"}} data-aos="fade-up">
            <MarqueeHandler stDirection={"right"} />
            <MarqueeHandler stDirection={"left"} />
        </Container>
        
        {/* categore node */}
        <Container style={{marginTop:"8vw"}} data-aos="fade-up">
            <Row>
                { categoriesList && categoriesList.slice(0,12).map((catItem)=>(
                    <Col md={4} sm={12} className="p-2" style={{fontSize : "1.4rem"}}>
                        <Card onClick={()=>{cateTableHandler(catItem.name, catItem.code) }} style={{cursor : "pointer", backgroundColor : "#FFFFFF"}} 
                                onMouseMove={e => { e.target.style.backgroundColor = "#878c8b";}}
                                onMouseOut={e => {e.target.style.backgroundColor = "#FFFFFF"}}
                            >
                                <Card.Body style={{fontWeight:"bolder", fontFamily:"monospace"}}>
                                    <i style={{color:"red"}} className={catItem.pic + " me-2"} />
                                    {catItem.name}
                                </Card.Body>
                            </Card>
                    </Col>
                ))}
            </Row>
        </Container>

        <Container className="text-center pt-2" style={{marginTop:"8vw"}} data-aos="fade-up">
            <Row>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <Image className="" src={jobWork1} fluid/>
                </Col>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <h3 style={{fontWeight:"bold"}} className="display-6">
                        Choice your career build company information to reviews
                        <h4 className="mt-4">
                            <Button onClick={() => {historyObj(`/categories/C1000/Biotechnology`);}}><i class="bi bi-search"/> Explore Company</Button>
                        </h4>
                    </h3>
                </Col>
            </Row>
        </Container>
        
        <Container className="text-center pt-2" style={{marginTop:"4vw"}} data-aos="fade-up">
            <Row>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <Image className="" src={experiance} fluid/>
                </Col>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <h3 style={{fontWeight:"bold"}} className="display-6">
                        Share your feedback on compliance or enhancement anything will expose
                        <h4 className="mt-4">
                            <Button onClick={() => {historyObj(`/categories`);}}><i class="bi bi-pencil-square"/>Write Note</Button>
                        </h4>
                    </h3>
                </Col>
            </Row>
        </Container>

        <Container className="text-center pt-2" style={{marginTop:"4vw"}}>
            <Row>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <Image className="" src={mainImg2} fluid/>
                </Col>
                <Col className="justify-content-center align-items-center d-flex flex-column flex-lg-row" xs={12} md={6}>
                    <h3 style={{fontWeight:"bold"}} className="display-6">
                        Search education insituation reviews to avoid career mistakes
                        <h4 className="mt-4">
                            <Button onClick={() => {historyObj(`/categories/C1003/Education%20management`);}}><i class="bi bi-search"/> Explore insituation</Button>
                        </h4>
                    </h3>
                </Col>
            </Row>
        </Container>

    </div>
    </>)
}

export default HomePage;