
import { useState, useEffect } from "react"
import { Container, Row, Col, Card } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';

import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"

function Categories(){
    const historyObj = useNavigate();
    const [categoriesList, setCategoriesList] = useState([]);

    useEffect(()=>{
        getCategoriesList();
    }, [])

    async function getCategoriesList(){
        const responce = await fetch("/api/v1/category")
        const jsonData = await responce.json();
        if (responce.status === 200){
            setCategoriesList(jsonData);
        }
        
    }

    const cateTableHandler = (value, cateCode) => {
        console.log(value)
        historyObj(`/categories/${cateCode}/${value}`);
    };
    return(<>
        <Container>
            <Row>
                <Col md={3} />
                <Col md={6} style={{marginTop:120, marginBottom: 80}} className="text-center justify-content-center">
                    <h2 >What are you looking for ?</h2>
                </Col>
                <Col md={3} />
            </Row>
        </Container>
        <Container>
        <Row className="mb-5 p-1">
            {
                categoriesList && categoriesList.map((item) => (
                    <Col md={4} xs={12} className="p-2">
                        <Card className="cateList" onClick={()=>{cateTableHandler(item.name , item.code) }} style={{cursor : "pointer", backgroundColor : "#FFFFFF"}} 
                            onMouseMove={e => { e.target.style.backgroundColor = "#878c8b";}}
                            onMouseOut={e => {e.target.style.backgroundColor = "#FFFFFF"}}
                        >
                            <Card.Body style={{fontWeight:"bolder", fontFamily:"monospace"}}>
                                <i className={item.pic + " me-3"}/>
                                {item.name}
                            </Card.Body>
                        </Card>
                    </Col>
                ))
            }
            </Row>
        </Container>
    </>)
}

export default Categories;